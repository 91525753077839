import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {exerciseActionMessages} from './exerciseActionMessages';

export const useMovingHomeworkErrorMessage = () => {
  const {formatMessage} = useIntl();
  return useCallback(
    (key?: string) => {
      if (key === 'removeFail')
        return formatMessage(exerciseActionMessages.DeletingExerciseFromHomeworkError);
      if (key === 'addFail')
        return formatMessage(exerciseActionMessages.AssigningExerciseToHomeworkError);
      else return formatMessage(exerciseActionMessages.GeneralServerError);
    },
    [formatMessage]
  );
};
