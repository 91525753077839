import React, {type FC, useCallback, useState} from 'react';
import classNames from 'classnames';
import {defineMessages, type IntlShape, useIntl} from 'react-intl';

import Icon from 'components/Icon';
import Confirm from 'components/modals/Confirm';
import DropDown from 'components/DropDown';

import bookmarkUrl from './bookmark.svg';
import bookmarkOutlineUrl from './bookmark-outline.svg';
import bookmarkInactive from './bookmark-inactive.svg';
import './HomeworkButton.scss';

interface Props {
  toggle(moving?: boolean): void;
  given?: boolean;
  isStudent: boolean;
  isDraft: boolean;
  awaiting: boolean;
}

export const homeworkExerciseControlMessages = defineMessages({
  confirmDelete: {
    id: 'XPlayer.ExerciseActions.Homework.Delete'
  },
  confirmMove: {
    id: 'XPlayer.ExerciseActions.Homework.MoveToHomework'
  },
  removeFromHomework: {
    id: 'File.DeleteFromHomework'
  },
  moveHomeworkToDraft: {
    id: 'File.MoveHomeworkToDraft'
  }
});

const HomeworkButton: FC<Props> = ({awaiting, isDraft, given, toggle, isStudent}) => {
  const intl = useIntl();
  const [isShowConfirm, setShowConfirm] = useState(false);
  const [isShowMoveConfirm, setIsShowMoveConfirm] = useState<boolean>(false);
  const inactive = !awaiting && given && !isDraft;
  const isAlreadyAdded = given && !isDraft;

  const onDropdownChange = useCallback((key: string) => {
    if (key === 'removeHW') {
      setShowConfirm(true);
    } else {
      setIsShowMoveConfirm(true);
    }
  }, []);

  return (
    <>
      {!isAlreadyAdded || isStudent ? (
        <button
          className={classNames('btn btn-ico bookmark', {awaiting, given, inactive})}
          style={{
            backgroundImage: `url("${
              !given || awaiting
                ? bookmarkOutlineUrl
                : isStudent || isDraft
                  ? bookmarkUrl
                  : bookmarkInactive
            }")`
          }}
          disabled={inactive || awaiting}
          onClick={() => (given ? setShowConfirm(true) : toggle())}
        >
          <Icon name={awaiting ? 'refresh' : 'virc-homework'} spin={awaiting} />
        </button>
      ) : (
        <HomeworkDropdown
          awaiting={awaiting}
          given={given}
          onDropdownChange={onDropdownChange}
          intl={intl}
        />
      )}
      {given && (
        <Confirm
          headerText={intl.formatMessage(homeworkExerciseControlMessages.confirmDelete)}
          show={isShowConfirm}
          onAccept={() => {
            toggle();
            setShowConfirm(false);
          }}
          onDecline={() => setShowConfirm(false)}
          disableButtons={awaiting}
        />
      )}
      <Confirm
        headerText={intl.formatMessage(homeworkExerciseControlMessages.confirmMove)}
        show={isShowMoveConfirm}
        onAccept={() => {
          toggle(true);
          setIsShowMoveConfirm(false);
        }}
        onDecline={() => setIsShowMoveConfirm(false)}
        disableButtons={awaiting}
      />
    </>
  );
};

interface HomeworkDropdownProps {
  awaiting: boolean;
  given?: boolean;
  onDropdownChange: (key: string) => void;
  intl: IntlShape;
}

const HomeworkDropdown: FC<HomeworkDropdownProps> = ({awaiting, given, onDropdownChange, intl}) => (
  <DropDown
    toggleClassName={classNames('btn btn-ico bookmark', {awaiting, given})}
    items={{
      removeHW: {
        icon: 'virc-homework-remove',
        value: intl.formatMessage(homeworkExerciseControlMessages.removeFromHomework)
      },
      moveHWtoDraft: {
        icon: 'virc-homework-add',
        value: intl.formatMessage(homeworkExerciseControlMessages.moveHomeworkToDraft)
      }
    }}
    value={
      <div
        className="dropdown"
        style={{
          backgroundImage: `url("${bookmarkUrl}")`
        }}
      >
        <Icon name={awaiting ? 'refresh' : 'virc-homework'} spin={awaiting} />
      </div>
    }
    placement="bottom"
    onChange={onDropdownChange}
    trigger={['click']}
    noCaret={true}
  />
);

export default HomeworkButton;
