import {defineMessages} from 'react-intl';

export const exerciseActionMessages = defineMessages({
  AssigningExerciseToHomeworkError: {
    id: 'XPlayer.AssigningExerciseToHomeworkError'
  },
  DeletingExerciseFromHomeworkSuccess: {
    id: 'XPlayer.DeletingExerciseFromHomeworkSuccess'
  },
  DeletingExerciseFromHomeworkError: {
    id: 'XPlayer.DeletingExerciseFromHomeworkError'
  },
  ViewInCoursebook: {
    id: 'XPlayer.ViewExerciseInCoursebook'
  },
  HideAdditionalTitle: {
    id: 'XPlayer.HideAdditionalTitle'
  },
  HideAdditionalConfirm: {
    id: 'XPlayer.HideAdditionalConfirm'
  },
  HideAdditionalFail: {
    id: 'XPlayer.HideAdditionalFail'
  },
  MoveHomeworkToDraftSuccess: {
    id: 'XPlayer.MoveHomeworkToDraftSuccess'
  },
  GeneralServerError: {
    id: 'WampDisconnected.description'
  }
});
