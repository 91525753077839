import {useCallback, useEffect, useRef, useState} from 'react';

import {type AxiosRequestAction, type AxiosResponseAction} from 'services/axios/interface';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';

const useSendApiRequest = <D, A extends unknown[]>(
  actionCreator: (...args: A) => AxiosRequestAction,
  args: A,
  successHandler: (data: D) => void,
  failHandler?: () => void
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const activeRequest = useRef<undefined | CancellablePromise>();
  const successHandlerRef = useRef<(data: D) => void | undefined>();
  successHandlerRef.current = successHandler;
  const failHandlerRef = useRef<() => void | undefined>();
  failHandlerRef.current = failHandler;

  const dispatch = useAxiosDispatch();

  const sendRequest = useCallback<() => void>(() => {
    if (activeRequest.current) {
      activeRequest.current.cancel();
    }
    setIsError(false);
    setIsLoading(true);

    activeRequest.current = makeCancellable(
      dispatch<AxiosResponseAction<D>>(actionCreator(...args)),
      response => {
        setIsLoading(false);
        successHandlerRef.current!(response.payload.data);
      },
      e => {
        if (import.meta.env.MODE === 'development') {
          console.error('Hook useSendApiRequest fetch error:', e); // eslint-disable-line no-console
        }
        setIsError(true);
        setIsLoading(false);
        failHandlerRef.current && failHandlerRef.current();
      }
    );
  }, [activeRequest, actionCreator, dispatch, successHandlerRef, failHandlerRef, [...args]]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => () => {
      activeRequest.current && activeRequest.current.cancel();
    },
    [activeRequest]
  );

  return {
    isLoading,
    isError,
    call: sendRequest
  };
};

export default useSendApiRequest;
